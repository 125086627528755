import { useState } from 'react';

import { MTLSCertOptions } from '@/react/portainer/settings/EdgeComputeView/EdgeComputeSettings/types';

import { FileUploadField } from '@@/form-components/FileUpload';
import { FormControl } from '@@/form-components/FormControl';
import { Switch } from '@@/form-components/SwitchField/Switch';
import { TextTip } from '@@/Tip/TextTip';

import { CertificateModalButton } from './CertificateModal/CertificateModal';
import {
  useEdgeMTLSCertificates,
  useEdgeMTLSCACertificates,
} from './queries/useEdgeMTLSCertificates';

interface Props {
  values: MTLSCertOptions;
  onChange(value: MTLSCertOptions): void;
}

export function MTLSOptions({ onChange, values }: Props) {
  const [valuesCache, setValuesCache] = useState(values);

  const mtlsCertificateQuery = useEdgeMTLSCertificates();
  const mtlsCACertificateQuery = useEdgeMTLSCACertificates();

  function onChangeField(key: string, newValue: unknown) {
    const newValues = {
      ...values,
      [key]: newValue,
    };
    onChange(newValues);
  }

  function onChangeUseSeparateCert(newValue: boolean) {
    if (newValue) {
      onChange({
        ...valuesCache,
        UseSeparateCert: true,
      });
    } else {
      setValuesCache(values);
      onChange({
        UseSeparateCert: false,
      });
    }
  }

  return (
    <>
      <FormControl
        inputId="enable_mtls"
        label="Enable mTLS"
        size="small"
        tooltip=""
        className="mb-1"
      >
        <Switch
          id="use_separete_cert"
          data-cy="use-separate-cert-switch"
          name="name_use_separate_cert"
          checked={!!values.UseSeparateCert}
          onChange={() => onChangeUseSeparateCert(!values.UseSeparateCert)}
        />
      </FormControl>

      <TextTip color="blue">
        Enable mTLS as an extra layer of security to protect the communications
        between the Edge Agent and Portainer Server.
      </TextTip>
      <br />
      <TextTip color="orange" className="mb-2 icon-orange">
        If you add or change the mTLS CA certificate you will need to restart
        the Portainer Server (and ensure any Edge Agents using mTLS are also
        updated to use the new CA certificate) to apply the change.
      </TextTip>

      {values.UseSeparateCert && (
        <>
          <FormControl label="TLS CA certificate" inputId="ca-cert-field">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <FileUploadField
                inputId="ca-cert-field"
                data-cy="ca-cert-file-upload"
                onChange={(file) => onChangeField('CaCertFile', file)}
                value={values.CaCertFile}
              />
              <CertificateModalButton
                certificate={mtlsCACertificateQuery.data?.MTLSCACertificate}
              />
            </div>
          </FormControl>
          <FormControl label="TLS certificate" inputId="cert-field">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <FileUploadField
                inputId="cert-field"
                data-cy="cert-file-upload"
                onChange={(file) => onChangeField('CertFile', file)}
                value={values.CertFile}
              />
              <CertificateModalButton
                certificate={mtlsCertificateQuery.data?.MTLSCertificate}
              />
            </div>
          </FormControl>
          <FormControl label="TLS key" inputId="tls-key-field">
            <FileUploadField
              inputId="tls-key-field"
              data-cy="tls-key-file-upload"
              onChange={(file) => onChangeField('KeyFile', file)}
              value={values.KeyFile}
            />
          </FormControl>
        </>
      )}
    </>
  );
}
