import { useQuery } from '@tanstack/react-query';

import axios, { parseAxiosError } from '@/portainer/services/axios';
import { withError } from '@/react-tools/react-query';

import { Certificate } from '../types';

export interface EdgeMTLSCertificatesResponse {
  MTLSCertificate?: Certificate;
}

export interface EdgeMTLSCACertificatesResponse {
  MTLSCACertificate?: Certificate;
}

export const queryMTLSCertKey = [
  'settings',
  'edge',
  'mtls_certificates',
] as const;
export const queryMTLSCACertKey = [
  'settings',
  'edge',
  'mtls_ca_certificates',
] as const;

async function getEdgeMTLSCertificates() {
  try {
    const { data } = await axios.get<EdgeMTLSCertificatesResponse>(
      '/settings/edge/mtls_certificate'
    );
    return data;
  } catch (error) {
    throw parseAxiosError(error as Error);
  }
}

async function getEdgeMTLSCACertificates() {
  try {
    const { data } = await axios.get<EdgeMTLSCACertificatesResponse>(
      '/settings/edge/mtls_ca_certificate'
    );
    return data;
  } catch (error) {
    throw parseAxiosError(error as Error);
  }
}

export function useEdgeMTLSCertificates() {
  return useQuery(queryMTLSCertKey, getEdgeMTLSCertificates, {
    ...withError('Unable to get MTLS Certificate'),
  });
}

export function useEdgeMTLSCACertificates() {
  return useQuery(queryMTLSCACertKey, getEdgeMTLSCACertificates, {
    ...withError('Unable to get MTLS CA Certificate'),
  });
}
