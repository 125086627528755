import { K8sDistributionType } from '@/react/portainer/environments/types';
import { useCurrentEnvironment } from '@/react/hooks/useCurrentEnvironment';
import { PageHeader } from '@/react/components/PageHeader';
import { Microk8sUpdateWidget } from '@/react/kubernetes/cluster/HomeView/microk8s/Microk8sUpdateWidget';
import { OmniUpdateWidget } from '@/react/kubernetes/cluster/HomeView/omni/OmniUpdateWidget';
import { NodesDatatable } from '@/react/kubernetes/cluster/HomeView/NodesDatatable';
import { OmniNodesDatatable } from '@/react/kubernetes/cluster/HomeView/OmniNodesDatatable/OmniNodesDatatable';

import { ClusterResourceReservation } from './ClusterResourceReservation';

export function ClusterView() {
  const { data: environment } = useCurrentEnvironment();

  return (
    <>
      <PageHeader
        title="Cluster"
        breadcrumbs={[
          { label: 'Environments', link: 'portainer.endpoints' },
          {
            label: environment?.Name || '',
            link: 'portainer.endpoints.endpoint',
            linkParams: { id: environment?.Id },
          },
          'Cluster information',
        ]}
        reload
      />

      <ClusterResourceReservation />

      {environment?.CloudProvider?.Provider?.toLowerCase() ===
        K8sDistributionType.MICROK8S && <Microk8sUpdateWidget />}

      {environment?.CloudProvider?.Provider === K8sDistributionType.OMNI && (
        <OmniUpdateWidget />
      )}

      <div className="row">
        {environment?.CloudProvider?.Provider === K8sDistributionType.OMNI ? (
          <OmniNodesDatatable />
        ) : (
          <NodesDatatable />
        )}
      </div>
    </>
  );
}
