import clsx from 'clsx';
import moment from 'moment-timezone';
import { Dispatch, SetStateAction, useState } from 'react';

import { Button } from '@@/buttons';
import { Modal } from '@@/modals';
import { NavTabs } from '@@/NavTabs';

import { Certificate, Name } from '../types';

import styles from './CertificateModal.module.css';

export function CertificateModalButton({
  certificate,
}: {
  certificate?: Certificate;
}) {
  const [isCertificateModalVisible, setIsCertificateModalVisible] =
    useState(false);
  const [certificateModalTab, setCertificateModalTab] = useState('summary');

  return (
    <>
      {certificate && (
        <Button
          onClick={() => setIsCertificateModalVisible(true)}
          data-cy="show-cert"
          title="Show Cert"
          color="secondary"
        >
          Show Certificate
        </Button>
      )}
      {isCertificateModalVisible && certificate && (
        <CertificateModal
          cert={certificate}
          closeModal={() => setIsCertificateModalVisible(false)}
          tab={certificateModalTab}
          setTab={setCertificateModalTab}
        />
      )}
    </>
  );
}

function CertificateModal({
  cert,
  closeModal,
  tab,
  setTab,
}: {
  cert: Certificate;
  closeModal: () => void;
  tab: string;
  setTab: Dispatch<SetStateAction<string>>;
}) {
  return (
    <Modal onDismiss={closeModal} aria-label="mTLS Certificate" size="lg">
      <Modal.Header title="Server MTLS Certificate" />

      <Modal.Body>
        <NavTabs<string>
          onSelect={setTab}
          selectedId={tab}
          type="tabs"
          justified
          options={[
            {
              id: 'summary',
              label: 'Summary',
              children: (
                <div
                  className={clsx(
                    styles.certModal,
                    'max-h-[80vh] overflow-auto'
                  )}
                >
                  <SectionTitle title="Subject Name" />
                  {cert.Subject && <NameComponent name={cert.Subject} />}

                  <br />
                  <SectionTitle title="Issuer Name" />
                  {cert.Issuer && <NameComponent name={cert.Issuer} />}

                  <br />
                  <Section
                    title="Validity"
                    sectionItems={[
                      {
                        name: 'Not Before',
                        value: formatDate(cert.ValidNotBefore),
                      },
                      {
                        name: 'Not After',
                        value: formatDate(cert.ValidNotAfter),
                      },
                    ]}
                  />

                  <br />
                  <Section
                    title="Fingerprints"
                    sectionItems={[
                      {
                        name: 'SHA-256',
                        value: cert.SHA256Fingerprint,
                      },
                      {
                        name: 'SHA-1',
                        value: cert.SHA1Fingerprint,
                      },
                    ]}
                  />
                </div>
              ),
            },
            {
              id: 'details',
              label: 'Details',
              children: (
                <div
                  className={clsx(
                    styles.certModal,
                    'max-h-[80vh] overflow-auto'
                  )}
                >
                  <SectionTitle title="Subject Name" />
                  {cert.Subject && <NameComponent name={cert.Subject} />}

                  <br />
                  <SectionTitle title="Issuer Name" />
                  {cert.Issuer && <NameComponent name={cert.Issuer} />}

                  <br />
                  <Section
                    title="Validity"
                    sectionItems={[
                      {
                        name: 'Not Before',
                        value: formatDate(cert.ValidNotBefore),
                      },
                      {
                        name: 'Not After',
                        value: formatDate(cert.ValidNotAfter),
                      },
                    ]}
                  />

                  <br />
                  <Section
                    title="Subject Alt Names"
                    sectionItems={cert.SubjectAltDNSNames?.map((name) => ({
                      name: 'DNS Name',
                      value: name,
                    })).concat(
                      cert.SubjectAltEmailAddresses?.map((email) => ({
                        name: 'Email Address',
                        value: email,
                      })) ?? [],
                      cert.SubjectAltIpAddresses?.map((ip) => ({
                        name: 'IP Address',
                        value: ip,
                      })) ?? [],
                      cert.SubjectAltURIs?.map((uri) => ({
                        name: 'URI',
                        value: uri,
                      })) ?? []
                    )}
                  />

                  <br />
                  <Section
                    title="Public Key Info"
                    sectionItems={[
                      {
                        name: 'Algorithm',
                        value: cert.PublicKey.Algorithm,
                      },
                      {
                        name: 'Key Size',
                        value: cert.PublicKey.Size,
                      },
                      ...(cert.PublicKey.Exponent &&
                      cert.PublicKey.Exponent !== ''
                        ? [
                            {
                              name: 'Exponent',
                              value: cert.PublicKey.Exponent,
                            },
                          ]
                        : []),
                      ...(cert.PublicKey.Modulus &&
                      cert.PublicKey.Modulus !== ''
                        ? [
                            {
                              name: 'Modulus',
                              value: cert.PublicKey.Modulus,
                            },
                          ]
                        : []),
                      ...(cert.PublicKey.Value && cert.PublicKey.Value !== ''
                        ? [
                            {
                              name: 'Value',
                              value: cert.PublicKey.Value,
                            },
                          ]
                        : []),
                    ]}
                  />

                  <br />
                  <Section
                    title="Miscellaneous"
                    sectionItems={[
                      {
                        name: 'Serial Number',
                        value: cert.SerialNumber,
                      },
                      {
                        name: 'Signature Algorithm',
                        value: cert.SignatureAlgorithm,
                      },
                      { name: 'Version', value: cert.Version },
                    ]}
                  />

                  <br />
                  <Section
                    title="Fingerprints"
                    sectionItems={[
                      {
                        name: 'SHA-256',
                        value: cert.SHA256Fingerprint,
                      },
                      {
                        name: 'SHA-1',
                        value: cert.SHA1Fingerprint,
                      },
                    ]}
                  />

                  <br />
                  <Section
                    title="Basic Contraints"
                    sectionItems={[
                      {
                        name: 'Certificate Authority',
                        value: cert.IsCertificateAuthority ? 'Yes' : 'No',
                      },
                    ]}
                  />

                  <br />
                  <Section
                    title="Key Usages"
                    sectionItems={[
                      {
                        name: 'Purposes',
                        value: cert.KeyUsages && cert.KeyUsages.join(','),
                      },
                    ]}
                  />

                  <br />
                  <Section
                    title="Extended Key Usages"
                    sectionItems={[
                      {
                        name: 'Purposes',
                        value:
                          cert.ExtendedKeyUsages &&
                          cert.ExtendedKeyUsages.join(','),
                      },
                    ]}
                  />

                  <br />
                  <Section
                    title="Subject Key ID"
                    sectionItems={[
                      {
                        name: 'Key ID',
                        value: cert.SubjectKeyId,
                      },
                    ]}
                  />

                  <br />
                  <Section
                    title="Authority Key ID"
                    sectionItems={[
                      {
                        name: 'Key ID',
                        value: cert.AuthorityKeyId,
                      },
                    ]}
                  />

                  {cert.CRLDistributionPoints && (
                    <>
                      <br />
                      <Section
                        title="CRL Endpoints"
                        sectionItems={cert.CRLDistributionPoints.map((dp) => ({
                          name: 'Distribution Point',
                          value: dp,
                        }))}
                      />
                    </>
                  )}

                  {(cert.OCSPServers || cert.IssuingCertificateURLs) && (
                    <>
                      <br />
                      <Section
                        title="Authority Info (AIA)"
                        sectionItems={[
                          ...(cert.OCSPServers?.flatMap((server) => [
                            { name: 'Location', value: server },
                            {
                              name: 'Method',
                              value:
                                'Online Certificate Status Protocol (OCSP)',
                            },
                          ]) ?? []),
                          ...(cert.IssuingCertificateURLs?.flatMap((url) => [
                            { name: 'Location', value: url },
                            { name: 'Method', value: 'CA Issuers' },
                          ]) ?? []),
                        ]}
                      />
                    </>
                  )}

                  {cert.Policies && cert.Policies.length > 0 && (
                    <>
                      <br />
                      <Section
                        title="Certificate Policies"
                        sectionItems={cert.Policies.map((policy) => ({
                          name: 'Policy',
                          value: policy,
                        }))}
                      />
                    </>
                  )}
                </div>
              ),
            },
          ]}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="w-full"
          onClick={closeModal}
          data-cy="portainerMTLSCertificateModal-CloseButton"
        >
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const dateTimeFormat = 'ddd, D MMM YYYY HH:mm:ss zz';

function formatDate(d?: Date) {
  if (d) {
    return (
      moment(d).format(dateTimeFormat) + moment.tz(moment.tz.guess()).zoneAbbr()
    );
  }
  return '';
}

interface Item {
  name: string;
  value: string | number | undefined;
}

function SectionTitle({ title }: { title: string }) {
  return <h4>{title}</h4>;
}

function SectionItem({
  name,
  value,
}: {
  name: string;
  value: string | number | undefined;
}) {
  return (
    <tr>
      <td className={styles.sectionItemCell}>{name}</td>
      <td className={styles.sectionItemCell}>{value}</td>
    </tr>
  );
}

function Section({
  title,
  sectionItems,
}: {
  title: string;
  sectionItems: Array<Item> | undefined;
}) {
  return (
    <>
      <SectionTitle title={title} />
      {sectionItems && (
        <table>
          {sectionItems.map(({ name, value }) => (
            <SectionItem key={`${title}_${name}`} name={name} value={value} />
          ))}
        </table>
      )}
    </>
  );
}

function NameComponent({ name }: { name: Name }) {
  return (
    <table>
      {name.Country && name.Country.length > 0 && (
        <SectionItem name="Country" value={name.Country.join(',')} />
      )}
      {name.Province && name.Province.length > 0 && (
        <SectionItem name="State/Province" value={name.Province.join(',')} />
      )}
      {name.Locality && name.Locality.length > 0 && (
        <SectionItem name="Locality" value={name.Locality.join(',')} />
      )}
      {name.StreetAddress && name.StreetAddress.length > 0 && (
        <SectionItem
          name="Street Address"
          value={name.StreetAddress.join(',')}
        />
      )}
      {name.PostalCode && name.PostalCode.length > 0 && (
        <SectionItem name="Postal Code" value={name.PostalCode.join(',')} />
      )}
      {name.Organization && name.Organization.length > 0 && (
        <SectionItem name="Organization" value={name.Organization.join(',')} />
      )}
      {name.OrganizationUnit && name.OrganizationUnit.length > 0 && (
        <SectionItem
          name="Organization Unit"
          value={name.OrganizationUnit.join(',')}
        />
      )}
      <SectionItem name="Common Name" value={name.CommonName} />
    </table>
  );
}
